#collasible-nav-dropdown{
    color: white;
font-weight: bold;
font-size: large;
}

/*@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700');*/


  footer {
    color: #d5d5d5;
    padding-top: 2rem;
  }
  
  hr.light{
    border-top: 1px solid #d5d5d5;
    margin-top: .8rem;
    margin-bottom: 1rem;
  }
  
  hr.light-100{
    border-top: 1px solid #d5d5d5;
    margin-top: .8rem;
    margin-bottom: 1rem;
  }
  
  footer a{
    color: #d5d5d5;
  }